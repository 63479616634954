import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { UserProvider, useUser } from './UserContext';
import Registration from './components/Registration/Registration';
import Portfolio from './components/Portfolio/Portfolio';
import Portal from './components/Portal/Portal'; // Importing the Portal component

function RoutesBasedOnAuth() {
    const { isRegistered} = useUser(); 

    if (isRegistered) {
        return (
            <Routes>
                <Route path="/portal" element={<Portal />} /> {/* Added Portal route */}
                <Route path="*" element={<Navigate to="/portal" />} /> {/* Default navigation */}
            </Routes>
        );
    }
    return (
        <Routes>
            <Route path="/" element={<Registration />} />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
}

function App() {
    return (
        <Router>
            <UserProvider>
                <Routes>
                    <Route path="*" element={<RoutesBasedOnAuth />} />
                </Routes>
            </UserProvider>
        </Router>
    );
}

export default App;
