import React from 'react';
import './CandidateCard.css';
import AnalyzeCandidateData from './AnalyzeCandidateData';
import linkedinIcon from './linkedin-icon.png';
import emailIcon from './email-icon.png';
import phoneIcon from './phone-icon.png';
import compensationLogo from './Compensation-Logo.png';
import educationLogo from './Education-Logo.png';
import languagesLogo from './Languages-Logo.png';
import notesLogo from './Notes-Logo.png';
import documentsLogo from './Documents-Logo.png';


const CandidateCard = ({ candidateData }) => {
	
 	
  const { 
	name, location, compensation, compensationNum, currentCompany, currentPosition,
    email, phone, context,language1, language2, language3, education1, education2, education3, notes, profileImage, url,  files,  status, tags
  } = candidateData;
  
  const renderMultiSelect = (items) => items.map((item, index) => (
	<span key={index} className={`badge ${item.color}`}>{item.name}</span>
  ));
  console.log("Candidate Data:", candidateData);
  
  
 const formatRichText = (richTextArray) => {
   return richTextArray.flatMap((textObj, index) => {
	 const { text, annotations, plain_text, href } = textObj;
 
	 // Splitting text content by newline characters
	 let splitContent = plain_text.split('\n');
 
	 return splitContent.map((content, contentIndex) => {
	   let formattedText = <span key={`${index}-${contentIndex}`}>{content}</span>;
 
	   // Apply annotations
	   if (annotations) {
		 if (annotations.bold) {
		   formattedText = <strong key={`${index}-${contentIndex}`}>{formattedText}</strong>;
		 }
		 if (annotations.italic) {
		   formattedText = <em key={`${index}-${contentIndex}`}>{formattedText}</em>;
		 }
		 if (annotations.strikethrough) {
		   formattedText = <del key={`${index}-${contentIndex}`}>{formattedText}</del>;
		 }
		 if (annotations.underline) {
		   formattedText = <u key={`${index}-${contentIndex}`}>{formattedText}</u>;
		 }
		 if (annotations.code) {
		   formattedText = <code key={`${index}-${contentIndex}`}>{formattedText}</code>;
		 }
		 // Handle color if needed
		 if (annotations.color !== 'default') {
		   formattedText = <span key={`${index}-${contentIndex}`} style={{ color: annotations.color }}>{formattedText}</span>;
		 }
	   }
 
	   // If there's a hyperlink, wrap the formattedText in an anchor tag
	   if (href) {
		 formattedText = <a href={href} key={`${index}-${contentIndex}`} target="_blank" rel="noopener noreferrer">{formattedText}</a>;
	   }
 
	   // Return formatted text with a line break if it's not the last segment
	   return contentIndex < splitContent.length - 1
		 ? [formattedText, <br key={`br-${index}-${contentIndex}`} />]
		 : formattedText;
	 });
   });
 };



  const formattedNotes = candidateData.notes ? formatRichText(candidateData.notes) : 'No notes available.';
  const formattedCompensation = candidateData.compensation ? formatRichText(candidateData.compensation) : 'Compensation data not available.';
  return (
	<div className="candidate-card">
	<div className="candidate-card-header">
		<div className="candidate-image-container">
			{profileImage && <img src={profileImage} alt={`${name} Profile`} className="candidate-profile-image" />}			
		</div>
		<div className="candidate-name-details">
			<h3 className="candidate-name">
			  {name} 
			  <span className="candidate-status">{renderMultiSelect(status)}</span>
			</h3>
			<p className="candidate-position-company">{currentPosition} - {currentCompany}</p>
			<p className="candidate-location">{location}</p>
			<div className="candidate-tags">
			  {renderMultiSelect(tags)}
			</div>
		</div>
		<div className="contact-container">
			<div className="contact-icons">
				<img src={linkedinIcon} alt="LinkedIn Icon" className="icon" />
				<a href={url} target="_blank" rel="noopener noreferrer">LinkedIn</a>
			</div>
			<div className="contact-icons">
				<img src={emailIcon} alt="Email Icon" className="icon" />
				<span>{email}</span>
			</div>
			<div className="contact-icons">
				<img src={phoneIcon} alt="Phone Icon" className="icon" />
				<span>{candidateData.phone}</span>
			</div>
		</div>
	</div>
	   <div className="candidate-details-wrapper">
	   <div className="details-group">
		 {/* Compensation Section */}
		 <div className="detail-item">
		   <div className="detail-title">
			 <strong><img src={compensationLogo} alt="Compensation Logo" className="title-icon" /> Compensation:</strong>
		   </div>
		   <div className="detail-scrollable-content">
			 <p>{formattedCompensation}</p>
		   </div>
		 </div>
		 {/* Education Section */}
		 <div className="detail-item">
		   <div className="detail-title">
			 <strong><img src={educationLogo} alt="Education Logo" className="title-icon" /> Education:</strong>
		   </div>
		   <div className="detail-scrollable-content">
			 <ul>
				{education1 && <li>{education1}</li>}
				{education2 && <li>{education2}</li>}
				{education3 && <li>{education3}</li>}
			  </ul>
		   </div>
		 </div>
		 {/* Languages Section */}
		 <div className="detail-item">
		   <div className="detail-title">
			 <strong><img src={languagesLogo} alt="Languages Logo" className="title-icon" /> Languages:</strong>
		   </div>
		   <div className="detail-scrollable-content">
			 <ul>
			   {language1 && <li>{language1}</li>}
			   {language2 && <li>{language2}</li>}
			   {language3 && <li>{language3}</li>}
			 </ul>
		   </div>
		 </div>
	   </div>
	   <div className="notes-and-documents">
		 {/* Notes Section */}
		 <div className="notes-group">
		   <div className="notes-title">
			 <strong><img src={notesLogo} alt="Notes Logo" className="notes-icon" /> Notes:</strong>
		   </div>
		   <div className="notes-scrollable-content">
			 {formattedNotes}
		   </div>
		 </div>
		 {/* Documents Section */}
		 <div className="documents-group">
		   <div className="documents-title">
			 <strong><img src={documentsLogo} alt="Documents Logo" className="documents-icon" />Documents:</strong>
		   </div>
		   <div className="documents-scrollable-content">
			 {files && files.length > 0 ? (
			   <ul>
				 {files.map((file, index) => (
				   <li key={index}>
					 <a href={file.url} target="_blank" rel="noopener noreferrer">{file.name}</a>
				   </li>
				 ))}
			   </ul>
			 ) : (
			   <p>No documents available.</p>
			 )}
		   </div>
		 </div>
	   </div>
		  <div className="chat-container">
			  <AnalyzeCandidateData candidateData={candidateData} />
		  </div>
	  </div>
	</div>
  );
};

export default CandidateCard;
