import React, { useEffect, useState, useRef } from 'react';
import Header from './Header';
import CandidateCard from './CandidateCard';
import './Portal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import listViewIcon from './list-view.svg';
import slideshowViewIcon from './slideshow-view.svg';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, Cell , Text, ResponsiveContainer} from 'recharts';



const Portal = () => {
  const [candidates, setCandidates] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isListView, setIsListView] = useState(true);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [filteredSlideshowCandidates, setFilteredSlideshowCandidates] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [allStatuses, setAllStatuses] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);  
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);
  const [showTagsDropdown, setShowTagsDropdown] = useState(false);
  const statusDropdownRef = useRef(null);
  const tagsDropdownRef = useRef(null);
  const statusOptions = allStatuses.map(status => ({ value: status, label: status }));
  const tagOptions = allTags.map(tag => ({ value: tag, label: tag }));
  const [activeTab, setActiveTab] = useState('Summary');
  
  
  // Helper function to calculate median
  function calculateMedian(numbers) {
	  const sortedNumbers = [...numbers].sort((a, b) => a - b);
	  const midIndex = Math.floor(sortedNumbers.length / 2);
	  if (sortedNumbers.length % 2 === 0) {
		  return (sortedNumbers[midIndex - 1] + sortedNumbers[midIndex]) / 2;
	  }
	  return sortedNumbers[midIndex];
  }
  
  
  
  const processDataForSummary = (candidates) => {
	  // Count by Location
	  const locationCounts = candidates.reduce((acc, candidate) => {
		  acc[candidate.location] = (acc[candidate.location] || 0) + 1;
		  return acc;
	  }, {});
  
	  // Count by Status
	  const statusCounts = candidates.reduce((acc, candidate) => {
		  candidate.status.forEach(status => {
			  const statusName = status.name;
			  acc[statusName] = (acc[statusName] || 0) + 1;
		  });
		  return acc;
	  }, {});
  
	  // Count by Tags
	  const tagCounts = candidates.reduce((acc, candidate) => {
		  candidate.tags.forEach(tag => {
			  const tagName = tag.name;
			  acc[tagName] = (acc[tagName] || 0) + 1;
		  });
		  return acc;
	  }, {});
  
	  // Process compensationNum
	  const compensationNumbers = candidates
		  .map(candidate => parseFloat(candidate.compensationNum))
		  .filter(num => !isNaN(num)); // Filter out invalid numbers
  
	  const minCompensation = Math.min(...compensationNumbers);
	  const maxCompensation = Math.max(...compensationNumbers);
	  const medianCompensation = calculateMedian(compensationNumbers);
  
	  return { locationCounts, statusCounts, tagCounts, compensationStats: { minCompensation, maxCompensation, medianCompensation } };
  };


  
  

 
 
 const LocationBarChart = ({ data }) => {
   const chartData = Object.entries(data).map(([name, count]) => ({ name, count }));
   const barColors = ['#8884d8', '#82ca9d', '#ffc658', '#8dd1e1', '#a4de6c', '#d0ed57', '#ffc658'];
 
   return (
	 <div className="chart-container">
	   <h2 style={{ textAlign: 'center', fontSize: 16, fontWeight: 'bold' }}>Location</h2>
	   <ResponsiveContainer width="100%" height={150}>
		 <BarChart data={chartData} margin={{ top: 20, right: 30, left: -20, bottom: 30 }}>
		   <XAxis 
			 dataKey="name" 
			 tick={({ x, y, payload }) => (
			   <g transform={`translate(${x},${y})`}>
				 <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)" style={{ fontSize: '10px' }}>
				   {payload.value.length > 10 ? `${payload.value.substring(0, 10)}...` : payload.value}
				 </text>
			   </g>
			 )}
			 interval={0} // Ensure every label is shown
			 style={{ fontSize: '10px' }}
		   />
		   <YAxis 
			 tickFormatter={value => Number.isInteger(value) ? value : ''} 
			 style={{ fontSize: 10 }} 
		   />
		   <Tooltip />
		   <Bar dataKey="count">
			 {chartData.map((entry, index) => (
			   <Cell key={`cell-${index}`} fill={barColors[index % barColors.length]} />
			 ))}
		   </Bar>
		 </BarChart>
	   </ResponsiveContainer>
	 </div>
   );
 };
 
 const StatusBarChart = ({ data }) => {
   const chartData = Object.entries(data).map(([name, count]) => ({ name, count }));
   const barColors = ['#8dd1e1', '#a4de6c', '#d0ed57', '#ffc658', '#8884d8'];
 
   return (
	 <div className="chart-container">
	   <h2 style={{ textAlign: 'center', fontSize: 16, fontWeight: 'bold' }}>Status</h2>
	   <ResponsiveContainer width="100%" height={150}>
		 <BarChart data={chartData} margin={{ top: 20, right: 30, left: -20, bottom: 30 }}>
		   <XAxis 
			 dataKey="name" 
			 tick={({ x, y, payload }) => (
			   <g transform={`translate(${x},${y})`}>
				 <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)" style={{ fontSize: '10px' }}>
				   {payload.value.length > 10 ? `${payload.value.substring(0, 10)}...` : payload.value}
				 </text>
			   </g>
			 )}
			 interval={0} // Ensure every label is shown
			 style={{ fontSize: '10px' }}
		   />
		   <YAxis tickFormatter={value => Number.isInteger(value) ? value : ''} style={{ fontSize: 10 }} />
		   <Tooltip />
		   <Bar dataKey="count">
			 {chartData.map((entry, index) => (
			   <Cell key={`cell-${index}`} fill={barColors[index % barColors.length]} />
			 ))}
		   </Bar>
		 </BarChart>
	   </ResponsiveContainer>
	 </div>
   );
 };

 
 
const TagsBarChart = ({ data }) => {
   const chartData = Object.entries(data).map(([name, count]) => ({ name, count }));
   const barColors = ['#ffc658', '#82ca9d', '#8884d8', '#d0ed57', '#a4de6c'];
 
   return (
	 <div className="chart-container">
	   <h2 style={{ textAlign: 'center', fontSize: 16, fontWeight: 'bold' }}>Specialties</h2>
	   <ResponsiveContainer width="100%" height={150}>
		 <BarChart data={chartData} margin={{ top: 20, right: 30, left: -20, bottom: 30 }}>
		   <XAxis 
			 dataKey="name" 
			 tick={({ x, y, payload }) => (
			   <g transform={`translate(${x},${y})`}>
				 <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)" style={{ fontSize: '10px' }}>
				   {payload.value.length > 10 ? `${payload.value.substring(0, 10)}...` : payload.value}
				 </text>
			   </g>
			 )}
			 interval={0} // Ensure every label is shown
			 style={{ fontSize: '10px' }}
		   />
		   <YAxis tickFormatter={value => Number.isInteger(value) ? value : ''} style={{ fontSize: 10 }} />
		   <Tooltip />
		   <Bar dataKey="count">
			 {chartData.map((entry, index) => (
			   <Cell key={`cell-${index}`} fill={barColors[index % barColors.length]} />
			 ))}
		   </Bar>
		 </BarChart>
	   </ResponsiveContainer>
	 </div>
   );
 };
 
 const CompensationBarChart = ({ data }) => {
   const chartData = [
	 { name: 'Min', value: data.minCompensation },
	 { name: 'Median', value: data.medianCompensation },
	 { name: 'Max', value: data.maxCompensation }
   ];
 
   return (
	 <div className="chart-container">
	   <h2 style={{ textAlign: 'center', fontSize: 16, fontWeight: 'bold' }}>Compensation (USD p.a.g.)</h2>
	   <ResponsiveContainer width="100%" height={150}>
		 <BarChart data={chartData} margin={{ top: 20, right: 30, left: -20, bottom: 30 }}>
		   <XAxis dataKey="name" style={{ fontSize: 10 }} angle={-45} textAnchor="end" />
		   <YAxis style={{ fontSize: 10 }} />
		   <Tooltip />
		   <Bar dataKey="value" fill="#82ca9d" />
		 </BarChart>
	   </ResponsiveContainer>
	 </div>
   );
 };



 




  // Inside Portal component
  const [summaryData, setSummaryData] = useState(null);
  
  useEffect(() => {
	  if (candidates) {
		  const processedData = processDataForSummary(candidates);
		  setSummaryData(processedData);

	  }
  }, [candidates]);
  
  

  const handleTabChange = (tab) => {
	setActiveTab(tab);
  };

  
  
  const toggleStatusDropdown = () => {
	setShowStatusDropdown(!showStatusDropdown);
  };
  
  const toggleTagsDropdown = () => {
	setShowTagsDropdown(!showTagsDropdown);
  };
  
  
  const handleClickOutside = (event) => {
	if (statusDropdownRef.current && !statusDropdownRef.current.contains(event.target)) {
	  setShowStatusDropdown(false);
	}
	if (tagsDropdownRef.current && !tagsDropdownRef.current.contains(event.target)) {
	  setShowTagsDropdown(false);
	}
  };
  
  
  useEffect(() => {
	document.addEventListener('click', handleClickOutside, true);
  
	return () => {
	  document.removeEventListener('click', handleClickOutside, true);
	};
  }, []);

  
  const getAllUniqueTagsAndStatuses = (candidates) => {
	const allTags = new Set();
	const allStatuses = new Set();
  
	candidates.forEach(candidate => {
	  candidate.tags?.forEach(tag => allTags.add(tag.name));
	  candidate.status?.forEach(status => allStatuses.add(status.name)); // Corrected line
	});
  
	return { allTags: Array.from(allTags), allStatuses: Array.from(allStatuses) };
  };


  
  


  
 const handleSearchChange = (event) => {
   const newSearchTerm = event.target.value;

   const newFilteredCandidates = candidates.filter(candidate =>
	 (candidate.name?.toLowerCase() || "").includes(newSearchTerm.toLowerCase())
   );
 

 
   setSearchTerm(newSearchTerm);
   setFilteredSlideshowCandidates(newFilteredCandidates);
 
   // Immediately adjust currentSlide if necessary
   if (currentSlide >= newFilteredCandidates.length) {
	 setCurrentSlide(newFilteredCandidates.length > 0 ? newFilteredCandidates.length - 1 : 0);
   }
 };
 
 const handleStatusChange = selectedOptions => {
   setSelectedStatuses(selectedOptions.map(option => option.value));
 };


 const handleTagChange = (event) => {
   // Get the selected options from the event
   const selectedOptions = Array.from(event.target.options)
								 .filter(option => option.selected)
								 .map(option => option.value);
 
   setSelectedTags(selectedOptions);
 };



  
  const handleNext = () => {

	  setCurrentSlide(prev => {
		  if (filteredSlideshowCandidates.length === 0) return 0;
		  return (prev + 1) % filteredSlideshowCandidates.length;
	  });
  };
  
  const handlePrev = () => {

	  setCurrentSlide(prev => {
		  if (filteredSlideshowCandidates.length === 0) return 0;
		  return prev === 0 ? filteredSlideshowCandidates.length - 1 : prev - 1;
	  });
  };
  
  useEffect(() => {

	  // If the current slide index is greater than or equal to the length of the filtered candidates, reset it to 0
	  if (currentSlide >= filteredSlideshowCandidates.length) {
		  setCurrentSlide(0);
	  }
  }, [filteredSlideshowCandidates.length]);
  
 
 


  useEffect(() => {
	  const fetchData = async () => {
		try {
		  const response = await fetch('https://portal.pretiumsearch.com/api/fetchCandidates', {
			headers: {
			  'Authorization': `Bearer ${localStorage.getItem('authToken')}`
			}
		  });
		  const data = await response.json();
		  setCandidates(data);
  
		  const { allTags, allStatuses } = getAllUniqueTagsAndStatuses(data);
		  setAllTags(allTags);
		  setAllStatuses(allStatuses);
		} catch (error) {
		  console.error("Error fetching candidates:", error);
		}
	  };
  
	  fetchData();
	}, []);


  useEffect(() => {
	const filteredCandidates = candidates.filter(candidate => {
	  const matchesTags = selectedTags.length === 0 || selectedTags.some(tag => candidate.tags.map(t => t.name).includes(tag));
	  const matchesStatus = selectedStatuses.length === 0 || candidate.status.some(status => selectedStatuses.includes(status.name));
	  const matchesSearch = candidate.name.toLowerCase().includes(searchTerm.toLowerCase());
	  return matchesTags && matchesStatus && matchesSearch;
	});
  
	setFilteredSlideshowCandidates(filteredCandidates);
  }, [candidates, searchTerm, selectedTags, selectedStatuses]);




 


  return (
	<div className="portal-page">
	  <Header />
		<div className="portal-intro">
		  <div className="portal-intro-title">
			<h1 className="portal-title">Candidate Portal</h1>
			<div className="portal-tabs">
			  <button onClick={() => handleTabChange('Search')} className={activeTab === 'Search' ? 'active' : ''}>Search</button>
			  <button onClick={() => handleTabChange('Summary')} className={activeTab === 'Summary' ? 'active' : ''}>Summary</button>
			</div>
		  </div>
		  {activeTab === 'Search' && (
			<div className="portal-intro-content">
		  	<p className="portal-description">
				Explore our pool of candidates. Use the search bar to find candidates by skills, experience, or other criteria.
		  	</p>
		  	<div className="search-and-toggle-container">
				<input 
			  	type="text"
			  	className="search-bar"
			  	placeholder="Search candidates..."
			  	value={searchTerm}
			  	onChange={handleSearchChange}
				/>
				<FontAwesomeIcon icon={faSearch} className="search-icon" />
				<button onClick={() => setIsListView(true)} className={isListView ? "view-toggle active" : "view-toggle"}>
			  	<span className="tooltip">List View</span>
			  	<img src={listViewIcon} alt="List View" />
				</button>
				<button onClick={() => setIsListView(false)} className={!isListView ? "view-toggle active" : "view-toggle"}>
			  	<span className="tooltip">Slideshow View</span>
			  	<img src={slideshowViewIcon} alt="Slideshow View" />
				</button>
		  	</div>
		  	<div className="filter-container">
				<div className="status-filter">
			  	<label htmlFor="status-select">Status:</label>
			  	<Select
					id="status-select"
					isMulti
					name="statuses"
					options={statusOptions}
					className="basic-multi-select"
					classNamePrefix="select"
					onChange={selectedOptions => setSelectedStatuses(selectedOptions.map(option => option.value))}
			  	/>
				</div>
				<div className="specialty-filter">
			  	<label htmlFor="tag-select">Specialty:</label>
			  	<Select
					id="tag-select"
					isMulti
					name="specialties"
					options={tagOptions}
					className="basic-multi-select"
					classNamePrefix="select"
					onChange={selectedOptions => setSelectedTags(selectedOptions.map(option => option.value))}
			  	/>
				</div>
		  	</div>
			</div>
			)}
		  {activeTab === 'Summary' && summaryData && (
			<div className='charts'>
			  <LocationBarChart data={summaryData.locationCounts} />
			  <StatusBarChart data={summaryData.statusCounts} />
			  <TagsBarChart data={summaryData.tagCounts} />
			  <CompensationBarChart data={summaryData.compensationStats} />
			</div>
		  )}
	  </div>
	  {!isListView && (
		<div className="slideshow-text-container">
		  <p>
			Showing {currentSlide + 1} of {filteredSlideshowCandidates.length} candidates
		  </p>
		</div>
	  )}
	 <div className={isListView ? 'list-view' : 'slideshow-view'}>
	 {isListView ? (
	   filteredSlideshowCandidates.length > 0 ? (
		 filteredSlideshowCandidates.map((candidate, index) => (
		   <CandidateCard key={index} candidateData={candidate} />
		 ))
	   ) : (
		 <div className="centered-message">
		   <p>No candidates found.</p>
		 </div>
	   )
	  	 ) : (
			 <div className="slideshow-container">
				 {filteredSlideshowCandidates.length === 0 ? (
					 <p>No matching candidates found.</p>
				 ) : (
					 <>
						 <button
							 onClick={handlePrev}
							 className="portalslideshow-prev-button"
							 disabled={filteredSlideshowCandidates.length <= 1}
						 >
							 <FontAwesomeIcon icon={faChevronLeft} />
						 </button>
						 <CandidateCard candidateData={filteredSlideshowCandidates[currentSlide]} />
						 <button
							 onClick={handleNext}
							 className="portalslideshow-next-button"
							 disabled={filteredSlideshowCandidates.length <= 1}
						 >
							 <FontAwesomeIcon icon={faChevronRight} />
						 </button>
					 </>
				 )}
			 </div>
		 )}
	 </div>
	</div>
  );
};

export default Portal;
